import styled from 'styled-components';

export const LeftContainer = styled.section`
  width: 100%;
  overflow-y: scroll;
  height: 100vh;

  /* @media (max-width: 823px) {
    height: auto;
  } */
`;
